import {Deserializablebsc} from "./deserializablebsc";
export class Category implements Deserializablebsc{
    _id:any;
    title:any;
    category_image:any;

    deserialize(input: any) {
      Object.assign(this, input);
      return this;
    }
}
