import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { config } from 'src/app/constants/config';
import { UserService } from 'src/app/services/user.service';
import * as moment from 'moment';
import { CountdownConfig } from 'ngx-countdown';
import { ItembscService } from 'src/app/services/itembsc.service';
import { CollectionbscService } from 'src/app/services/collectionbsc.service';
import { MatSnackBar } from '@angular/material/snack-bar';
const CountdownTimeUnits: Array<[string, number]> = [
  ['Y', 1000 * 60 * 60 * 24 * 365], // years
  ['M', 1000 * 60 * 60 * 24 * 30], // months
  ['D', 1000 * 60 * 60 * 24], // days
  ['H', 1000 * 60 * 60], // hours
  ['m', 1000 * 60], // minutes
  ['s', 1000], // seconds
  ['S', 1], // million seconds
];
@Component({
  selector: 'app-trendingbsc',
  templateUrl: './trendingbsc.component.html',
  styleUrls: ['./trendingbsc.component.css']
})
export class TrendingbscComponent implements OnInit {
  @Input() item: any;
  @Input() displayActions = false;
  @Output() itemEvent = new EventEmitter<any>();
  now: number;
  cdConfig: CountdownConfig;
  mediaBase: string = config.media_path2;
  currentUser: any;

  converted_prices = {
    usd: 0
  };

  isVideo: boolean = false;

  constructor(
    private itembscService: ItembscService,
    private userService: UserService,
    private collectionbscService: CollectionbscService,
    private snackBar: MatSnackBar
  ) {

    this.now = moment().unix();
    this.cdConfig = {
      leftTime: 0
    };
    this.userService.user.subscribe((user) => {
      this.currentUser = user;
    });
  }

  ngOnInit(): void {
    const file_extencion = this.item.thumb.split(".");
    if(file_extencion[file_extencion.length-1] == "mp4"){
      this.isVideo = true;
    }
    this.cdConfig = {
      leftTime: this.timeLeft(),
      formatDate: ({ date, formatStr }) => {
        let duration = Number(date || 0);

        return CountdownTimeUnits.reduce((current, [name, unit]) => {
          if (current.indexOf(name) !== -1) {
            const v = Math.floor(duration / unit);
            duration -= v * unit;
            return current.replace(new RegExp(`${name}+`, 'g'), (match: string) => {
              return v.toString().padStart(match.length, '0');
            });
          }
          return current;
        }, formatStr);
      },
    };

    setTimeout(() => {
      this.convert_price('MATIC', this.item.price, 'USD');
    }, 800);
  }

  convert_price = (symbol: string, amount: number, convert: string) => {
    this.itembscService.convert_price({
      symbol,
      amount,
      convert
    }).subscribe(async result => {
      this.converted_prices.usd = result.data.quote.USD.price.toFixed(4);
    });
  }

  /**
   * This is the function which used send notification to parent component for item view
   */
  viewAction = async () => {
    if(this.item.token_id == null){
      const ritem = await this.collectionbscService.openMysteryBox({collection_id: this.item.collection_id._id, collection_author_id: this.item.collection_id.author_id._id}).toPromise();
      if(ritem.message == "no found items"){
        this.snackBar.open('There are no items available in this box at this time', '');
        return
      }
      this.itemEvent.emit({
        type:"view",
        item:ritem.data
      });
    }else{
      this.itemEvent.emit({
        type:"view",
        item:this.item
      });
    }
  }

  /**
   * This is the function which used send notification to parent component for item edit
   */
  editAction = () => {
    this.itemEvent.emit({
      type:"edit",
      item:this.item
    });
  }

  /**
   * This is the function which used send notification to parent component for item delete
   */
  deleteAction = () => {
    this.itemEvent.emit({
      type:"delete",
      item:this.item
    });
  }

  /**
   * This is the function which used send notification to parent component for item publish
   */
  publishAction = () => {
    this.itemEvent.emit({
      type:"publish",
      item:this.item
    });
  }

  author = () => {
    const profile_image = this.item.collection_id?.author_id?.profile_image != "" ? this.item.collection_id?.author_id?.profile_image : 'nouser.jpg';
    return this.mediaBase + '/images/user/' + profile_image;
  }

  authorFullname = () => {
    return  `${this.item.collection_id?.author_id?.first_name} ${this.item.collection_id?.author_id?.last_name}`;
  }

  timeLeft = () => {
    if (this.item?.sell_method === 2) {
      if (this.now < this.item?.expire_at) {
        return this.item?.expire_at - this.now + 133;
      }
    }
    return 0;
  }

  isOwner = () =>  this.item?.current_owner?._id === this.currentUser?.user_id;
}