<div class="appContainer2">
    <div class="nft-main">
        <section id="section-hero" aria-label="section" class="no-top no-bottom vh-100"
                 style="background: url('../../../assets/images/home/fondo.png') center bottom / cover;">
            <div class="v-center" style="background-size: cover;">
                <div class="container" style="background-size: cover;">
                    <div class="row align-items-center" style="background-size: cover">
                        <div class="col-md-6" style="background-size: cover;">
                            <div class="spacer-single" style="background-size: cover;"></div>
                            <div class="spacer-10" style="background-size: cover;"></div>
                            <h1 class="wow fadeInUp animated changetextsize" data-wow-delay=".75s"
                                style="visibility: visible; animation-delay: 0.75s; animation-name: fadeInUp; color:white"
                               >Discover Digital Art, Collect and Sell Your NFTs</h1>
                            <div class="spacer-10" style="background-size: cover;"></div>
                            <a href="javascript:void(0)" routerLink="/marketplace"
                               class="newbtn btnsize3 wow fadeInUp lead animated" data-wow-delay="1.25s"
                               style="visibility: visible; animation-delay: 1.25s; animation-name: fadeInUp;"
                               translate>explore</a>
                            <div class="mb-sm-30" style="background-size: cover;"></div>
                        </div>
                        <div class="col-md-6 md-hide text-center" style="background-size: cover; padding-top: 70px;">
                            <app-items-carousel [itemsActivatedForSale]="itemsActivatedForSale()"></app-items-carousel>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section class="nft-trending no-top no-bottom" *ngIf="trendingItems.length > 0">
            <div class="nft-trending-head">
                <div class="container">
                    <div style="background-size: cover;">
                        <div class="text-left" style="background-size: cover;">
                            <h2 translate class="style-2">Trending</h2>
                            <div class="text-left small-border bg-color-2" style="margin-left: 0;"></div>
                        </div>
                    </div>
                    <!-- <ul class="top_tabs">
                        <li [ngClass]="{'active':collectionMenu == ''}" (click)="showItemByCollection('')"><a
                                href="javascript:void(0)" translate>All items</a></li>
                        <li *ngFor="let item of collectionsWithItems()" [ngClass]="{'active':item._id == collectionMenu}"
                            (click)="showItemByCollection(item._id)"><a href="javascript:void(0)">{{item.name}}</a></li>
                    </ul> -->
                </div>
            </div>
            <!-- <div class="nft-trending_body"> -->
            <div>
                <div class="container">
                    <app-itemskeleton *ngIf="loading"></app-itemskeleton>
                    <div class="row wow fadeIn" *ngIf="!loading && itemsActivatedForSale().length>0">
                        <!-- nft item begin -->
                        <!-- <div class="col-12"> -->
                        <app-trending [item]="item" (itemEvent)="itemEvent($event)" *ngFor="let item of trendingItems"
                            class="d-item col-lg-3 col-md-6 col-sm-6 col-xs-12">
                        </app-trending>
                        
                        <app-trendingbsc [item]="item" (itemEvent)="itemEventBsc($event)" *ngFor="let item of trendingItemsbsc"
                        class="d-item col-lg-3 col-md-6 col-sm-6 col-xs-12">
                    </app-trendingbsc>
                    </div>
                </div>
            </div>
        </section>




        <section id="section-intro" style=" background-repeat: no-repeat; background-size: 500px;background-image: url(''); padding-top: 50px!important"
        *ngIf="!loading && collectionItems.length>0">

        <div class="container" style="background-size: cover">
          
        <div class="row" style="background-size: cover;">
       
        
          <div class="col-lg-12" style="display: flex;
          flex-direction: row-reverse;
          justify-content: space-between;
          gap: 25px; padding-bottom: 2rem;">
     <div style="display: flex; gap: 18px;     align-items: center;">
        <a href="https://marketplace.devtop.online"><h5>VIEW ALL</h5> </a> 
        <a href="https://marketplace.devtop.online">     <i style="color: #00FFFF!important;     font-size: 35px;" class="cil-arrow-right" ></i> </a> 
      </div>
              <div class="text-left" style="background-size: cover; display: flex;
                  gap: 20PX;">
                        <div>
                            <img src="./../../../assets/images/Polygon.png"  alt="">
                        </div>
                        <div class="text-left">
                            <h2 translate class="style-2">Polygon Last items</h2>
                            <div class="text-left small-border bg-color-2" style="margin-left: 0;"></div>
                        </div>
              </div>
             
          </div>
          
        </div>
      
          <div class="row row-cols-1 row-cols-sm-2 row-cols-md-5 " >
              <app-itempolygon [item]="item" (itemEvent)="itemEvent($event)" *ngFor="let item of itemsActivatedForSale()" 
                        >
              </app-itempolygon>
          </div>

        </div>
    </section>

        <!-- row row-cols-1 row-cols-sm-2 row-cols-md-5 -->
        <!-- col-lg-3 col-md-5 col-sm-5 col-xs-12 -->
        <section id="section-intro" style=" background-repeat: no-repeat; background-size: 500px;background-image: url(''); padding-top: 50px!important"
        *ngIf="!loading && collectionItems.length>0">
   <div class="container" style="background-size: cover">
       <div class="row" style="background-size: cover;">
           
        <div class="col-lg-12" style="display: flex;
        flex-direction: row-reverse;
        justify-content: space-between;
        gap: 25px; padding-bottom: 2rem;">
   <div style="display: flex; gap: 18px;     align-items: center;">
      <a href="https://bscmarketplace.devtop.online"><h5>VIEW ALL</h5> </a> 
      <a href="https://bscmarketplace.devtop.online">     <i style="color: #00FFFF!important;     font-size: 35px;" class="cil-arrow-right" ></i> </a> 
    </div>
            <div class="text-left" style="background-size: cover; display: flex;
                gap: 20PX;">
                      <div>
                          <img src="./../../../assets/images/BSC.png"  alt="">
                      </div>
                      <div class="text-left">
                          <h2 translate class="style-2">BSC Last items</h2>
                          <div class="text-left small-border bg-color-2" style="margin-left: 0;"></div>
                      </div>
            </div>
           
        </div>
       </div>
       <div class="row" style="background-size: cover;">
           <div class="row row-cols-1 row-cols-sm-2 row-cols-md-5 wow fadeIn" >
               <app-itembsc [item]="item" (itemEvent)="itemEventBsc($event)" *ngFor="let item of itemsActivatedForSalebsc()" 
                         class="d-item">
               </app-itembsc>
           </div>
       </div>
   </div>
</section>







 

        <section class="no-top no-bottom">
            <div class="container">
                <div class="row" *ngIf="bestSellers.length > 0">
                    <div class="text-left" style="background-size: cover;">
                        <h2 translate class="style-2" translate>Top Sellers</h2>
                        <div class="text-left small-border bg-color-2" style="margin-left: 0;"></div>
                    </div>
                    <div class="col-md-12 wow fadeIn">
                        <ol class="gridResponsive" style=" display: grid;
                        grid-template-columns: 1fr 1fr 1fr 1fr;
                        /* grid-template-rows: 2fr 100px; */
                        grid-column-gap: 20px;
                        grid-row-gap: 40px;
                        justify-items: stretch;
                        align-items: stretch; padding-left: 0!important;" *ngIf="bestSellers">
                            <li  *ngFor="let item of bestSellers">
                                <div class="author_list_pp1"> 
                                    <a href="javascript:void" [href]="'https://marketplace.devtop.online/profile/'+item._id+'/created'">
                                        <img class="lazy" [src]="author(item)" alt="{{authorFullname(item)}}">
                                        <i class="fa fa-check"></i>
                                    </a>
                                </div>
                                <div class="author_list_info" style="background: rgb(32, 40, 76); height: 7vh;     padding-top: 1rem;">
                                    <a href="javascript:void"
                                    [href]="'https://marketplace.devtop.online/profile/'+item._id+'/created'">{{authorFullname(item)}}</a>
                                    <!-- <span>{{item.totalSaleAmount}} {{item.currency}}</span> -->
                                </div>
                            </li>
                        </ol>

                        <ol class="gridResponsive" style=" display: grid;
                        grid-template-columns: 1fr 1fr 1fr 1fr;
                        grid-template-rows: 2fr 100px;
                        grid-column-gap: 20px;
                        grid-row-gap: 40px;
                        justify-items: stretch;
                        align-items: stretch; padding-left: 0!important;" *ngIf="bestSellersbsc">
                            <li  *ngFor="let item of bestSellersbsc">
                                <div class="author_list_pp1"> 
                                    <a href="javascript:void" [href]="'https://bscmarketplace.devtop.online/profile/'+item._id+'/created'">
                                        <img class="lazy" [src]="authorbsc(item)" alt="{{authorFullname(item)}}">
                                        <i class="fa fa-check"></i>
                                    </a>
                                </div>
                                <div class="author_list_info" style="background: rgb(32, 40, 76); height: 7vh;     padding-top: 1rem;">
                                    <a href="javascript:void"
                                    [href]="'https://bscmarketplace.devtop.online/profile/'+item._id+'/created'">{{authorFullname(item)}}</a>
                                    <!-- <span>{{item.totalSaleAmount}} {{item.currency}}</span> -->
                                </div>
                            </li>
                        </ol>
                        
                    </div>
                </div>
            </div>
        </section> 


        <section style="background-color: rgb(19, 19, 35);">
            <div class="container">
                <div class="text-left" style="margin-bottom: 5rem; background-size: cover;">
                    <h2 translate class="style-2">Create your NFT</h2>
                    <div class="small-border bg-color-2" style="margin-left: 0;"></div>
                </div>
            </div>
          <div class="container">
            <div class="row" style="background-size: cover;">
                <div class="col-lg-3 col-md-6 col-sm-6 col-xs-12" style="background-size: cover; margin-bottom: 4rem;" >
                    <app-section-two-items [title]="'STEP 01'"[img]="'../../../assets/images/home/item.png'" [name]="'Connect Your wallet'" [description]="'Connect your wallet to the marketplace to register your account.'"  ></app-section-two-items>
                </div>
                <div class="col-lg-3 col-md-6 col-sm-6 col-xs-12" style="background-size: cover; margin-bottom: 4rem;">
                    <app-section-two-items [title]="'STEP 02'" [img]="'../../../assets/images/home/item2.png'" [name]="'Create your collection'" [description]="'Create your collection, name, royalties, select the sale token and publish.'" ></app-section-two-items>
                </div>
                <div class="col-lg-3 col-md-6 col-sm-6 col-xs-12" style="background-size: cover; margin-bottom: 4rem;">
                    <app-section-two-items [title]="'STEP 03'" [img]="'../../../assets/images/home/item3.png'" [name]="'Add your NFTs'" [description]="'Add the NFTs to your collection, you can add properties and different prices to your NFTs.'" ></app-section-two-items>
                </div>
                <div class="col-lg-3 col-md-6 col-sm-6 col-xs-12" style="background-size: cover; margin-bottom: 4rem;">
                    <app-section-two-items [title]="'STEP 04'" [img]="'../../../assets/images/home/item4.png'" [name]="'Sell your NFTs'" [description]="'Publish and sell your NFTs, you will receive royalties in the future while your NFTs are traded.'" ></app-section-two-items>
                </div>
        </div>
          </div>
    </section>
    </div>
</div>



