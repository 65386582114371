import {Deserializablebsc} from "./deserializablebsc";
export class History implements Deserializablebsc{
    _id:any;
    item_id:any;
    collection_id:any;
    from_id:any;
    to_id:any;
    transaction_hash:any;
    history_type:any;
    price:any;

    deserialize(input: any) {
      Object.assign(this, input);
      return this;
    }
}
