import { Category } from "./categorybsc";
import { Collection } from "./collectionbsc";
import { Deserializablebsc } from "./deserializablebsc";
import { History } from "./historybsc";
import { Item } from "./itembsc";
import { Offer } from "./offerbsc";
import { Price } from "./pricebsc";
import { Settoken } from "./settokenbsc"
import { Setacceptedtoken } from "./tokenacceptedbsc";
import { User } from "./user";

export class Common implements Deserializablebsc {
    return_id:any;
	status:any;
	message:any;
	tempArray: Array<any> = [];
	result:any;
	data: any;
    
    deserialize(input: any): this {
       return Object.assign(this, input);
    }

	deserializeLoggedIn(input: any): this {
		if(input.status == true) {
			this.return_id = input.token
		}
		return Object.assign(this, input);
	}

	deserializeCollectionList(input: any): this {
		Object.assign(this, input);
		if(input.status == true) {
			var users = [];
			for (let index = 0; index < input.data.docs.length; index++) {
				const element = input.data.docs[index];
				users.push(new Collection().deserialize(element));
			}
			this.tempArray = users
		}
		return this;
	}

	deserializeCategoryList(input: any): this {
		Object.assign(this, input);
		if(input.status == true) {
			var category = [];
			for (let index = 0; index < input.data.length; index++) {
				const element = input.data[index];
				category.push(new Category().deserialize(element));
			}
			this.tempArray = category
		}
		return this;
	}

	deserializeSettokenList(input: any): this {
		Object.assign(this, input);
		if(input.status == true) {
			var set = [];
			for (let index = 0; index < input.data.length; index++) {
				const element = input.data[index];
				set.push(new Settoken().deserialize(element));
			}
			this.tempArray = set
		}
		return this;
	}

	deserializeCollectionView(input:any):this {
		Object.assign(this, input);
		this.result = new Collection().deserialize(this.result)
		// console.log(this.result)
		return this
	}

	deserializeTokenAccepted(input:any):this {
		Object.assign(this, input);
		this.result = new Setacceptedtoken().deserialize(this.result)
		// console.log(this.result)
		return this
	}

	deserializeItemList(input: any): this {
		Object.assign(this, input);
		if(input.status == true) {
			var users = [];
			for (let index = 0; index < input.data.docs.length; index++) {
				const element = input.data.docs[index];
				users.push(new Item().deserialize(element));
			}
			this.tempArray = users
		}
		return this;
	}

	deserializeHistoryList(input: any): this {
		Object.assign(this, input);
		if(input.status == true) {
			var users = [];
			for (let index = 0; index < input.data.docs.length; index++) {
				const element = input.data.docs[index];
				users.push(new History().deserialize(element));
			}
			this.tempArray = users
		}
		return this;
	}

	deserializeOfferList(input: any): this {
		Object.assign(this, input);
		if(input.status == true) {
			var offers = [];
			for (let index = 0; index < input.data.docs.length; index++) {
				const element = input.data.docs[index];
				offers.push(new Offer().deserialize(element));
			}
			this.tempArray = offers
		}
		return this;
	}

	deserializePriceList(input: any): this {
		Object.assign(this, input);
		if(input.status == true) {
			var prices = [];
			for (let index = 0; index < input.data.docs.length; index++) {
				const element = input.data.docs[index];
				prices.push(new Price().deserialize(element));
			}
			this.tempArray = prices
		}
		return this;
	}

	deserializeFavouriteList(input: any): this {
		Object.assign(this, input);
		if(input.status == true) {
			var items = [];
			for (let index = 0; index < input.data.docs.length; index++) {
				const element = input.data.docs[index];
				items.push(new Item().deserialize(element.item_id));
			}
			this.tempArray = items

			console.log(this.tempArray)
		}
		return this;
	}
	
}