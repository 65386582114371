import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { Common } from '../models/common';
import { API } from '../constants/api';
import { JwtHelperService } from '@auth0/angular-jwt';
import { environment } from 'src/environments/environment';

declare var window: any;
const url = environment.url;
@Injectable({
  providedIn: 'root'
})
export class UserService {
  public notifier: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
  private userSubject: BehaviorSubject<any>;
  public user: Observable<any>;
  constructor(
    private http: HttpClient
  ) {
    this.userSubject = new BehaviorSubject<any>(this.getUser());
    this.user = this.userSubject.asObservable();
  }

  /**
   * This is the function which used to get user jwt token for local storage
   */
  getToken = () => {
    const token = localStorage.getItem('token');
    return token == null ? '' : token;
  }

  refreshUser = () => this.userSubject.next(this.getUser());

  /**
   * This is the function which used to parse jwt token using helper service
   */
  getUser() {
    const jwtHelper = new JwtHelperService();
    const token = this.getToken();
    if (token?.length > 0 /*&& window.ethereum?.selectedAddress?.length > 0*/) {
      return jwtHelper.decodeToken(token);
    } else {
      return null;
    }
  }

  loginApi(params: any): Observable<Common> {
    const url = API.base_url + API.user_login;
    return this.http.post<Common>(url, params).pipe(map((param: any) => new Common().deserializeLoggedIn(param)));
  }

  getChallenge(wallet: string): Observable<any> {
    const url = `${API.base_url}${API.user_auth}/${wallet}`;
    return this.http.get<any>(url);
  }

  loginWallet(message: string, signature: string): Observable<Common> {
    const url = `${API.base_url}${API.user_auth}/${message}/${signature}`;
    return this.http.get<Common>(url).pipe(map((param: any) => new Common().deserializeLoggedIn(param)));
  }

  register(params: any): Observable<Common> {
    const url = API.base_url + API.user_register;
    return this.http.post<Common>(url, params).pipe(map((param: any) => new Common().deserializeLoggedIn(param)));
  }

  bestSellers(): Observable<Common> {
    const url = API.base_url + API.user_best_sellers;
    return this.http.get<Common>(url).pipe(map((param: any) => new Common().deserialize(param)));
  }

  bestSellersbsc(): Observable<Common> {
    const url = API.base_url2 + API.user_best_sellers;
    return this.http.get<Common>(url).pipe(map((param: any) => new Common().deserialize(param)));
  }

  forgotPassword(params: any): Observable<Common> {
    const url = API.base_url + API.user_forgot;
    return this.http.put<Common>(url, params).pipe(map((param: any) => new Common().deserialize(param)));
  }

  resetPassword(params: any): Observable<Common> {
    const url = API.base_url + API.user_reset;
    return this.http.put<Common>(url, params).pipe(map((param: any) => new Common().deserializeLoggedIn(param)));
  }

  viewProfile(user_id: any): Observable<Common> {
    const url = API.base_url + API.user_profile + '/' + user_id;
    return this.http.get<Common>(url).pipe(map((param: any) => new Common().deserialize(param)));
  }

  updateApi(params: any, user_id: string): Observable<Common> {
    const url = API.base_url + API.user_update + '/' + user_id;
    return this.http.put<Common>(url, params).pipe(map((param: any) => new Common().deserializeLoggedIn(param)));
  }

  updateMetamaskAddress(params: any): Observable<Common> {
    const url = API.base_url + API.user_update_metamask;
    return this.http.put<Common>(url, params).pipe(map((param: any) => new Common().deserialize(param)));
  }

  setWallet(wallet: string) {
    if (wallet) return localStorage.setItem('wallet', wallet);
  }

  getWallet(): string {
    const wallet = localStorage.getItem('wallet');
    return wallet? wallet : '';
  }

  deleteWallet() {
    return localStorage.removeItem('wallet');
  }

  setReferId(referId: string) {
    if (referId) return localStorage.setItem('refer_id', referId);
  }

  getReferId(): string {
    const referId = localStorage.getItem('refer_id');
    return referId? referId : '';
  }

  deleteReferId() {
    return localStorage.removeItem('refer_id');
  }

  setMyReferId(myReferId: string) {
    if (myReferId) return localStorage.setItem('my_refer_id', myReferId);
  }

  getMyReferId(): string {
    const myReferId = localStorage.getItem('my_refer_id');
    return myReferId? myReferId : '';
  }

  deleteMyReferId() {
    return localStorage.removeItem('my_refer_id');
  }

}
