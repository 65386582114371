import {Deserializablebsc} from "./deserializablebsc";
export class Settoken implements Deserializablebsc{
    _id:any;
    index:any;
    name:any;
    tokenAddress:any;

    deserialize(input: any) {
      Object.assign(this, input);
      return this;
    }
}
