import {Deserializablebsc} from "./deserializablebsc";
export class Setacceptedtoken implements Deserializablebsc{
    collection_id:any;
    acceptedTokenIndex:any;
    acceptedToken:any;

    deserialize(input: any) {
      Object.assign(this, input);
      return this;
    }
}