<!-- header begin -->
<header class="transparent" [ngClass]="{'header-light scroll-light': apply_dark_theme == false}">
  <div class="container">
    <div class="row">
      <div class="col-md-12">
        <div class="de-flex sm-pt10">
          <div class="de-flex-col">
            <div class="de-flex-col">
              <!-- logo begin -->
              <div id="logo">
                <a routerLink="/">
                  <!-- <img alt="" class="logo" src="assets/images/logo-light.png">
                  <img alt="" class="logo-2" src="assets/images/logo.png"> -->
                  <!-- <img alt="" [ngClass]="{'logo-2': apply_dark_theme == false, 'logo': apply_dark_theme == true}"
                    [src]="apply_dark_theme ? 'assets/images/logo-light.png' :'assets/images/logo-2.png'" /> -->
                </a>
              </div>
              <!-- logo close -->
            </div>
            <!-- <div class="de-flex-col">
              <input id="quick_search" class="xs-hide style-2" name="quick_search" 
                placeholder="{{'search here...'|translate}}"
                [(ngModel)]="searchInput" (keyup.enter)="search()" type="text" />
            </div> -->
          </div>
          <div class="de-flex-col header-col-mid">
            <!-- mainmenu begin -->
            <ul id="mainmenu">
              <!-- <li [ngClass]="{'active': current_page == ''}">
                <a routerLink="/" href="javascript:void(0)" (click)="collapseMenu()" translate>Home</a>
              </li> -->
              <!-- <li [ngClass]="{'active': current_page == 'marketplace'}">
                <a href="javascript:void(0)" [routerLink]="['/']" >Marketplace</a>
                <ul>
                  <li><a href="javascript:void(0)" [routerLink]="['/marketplace']" translate>All NFTs</a></li>
                  <li *ngFor="let item of categoriesLimited">
                    <a href="javascript:void(0)" [routerLink]="['/marketplace']"
                      [queryParams]="{category_id: item._id}">{{item.title}}</a>
                  </li>
                </ul>
              </li> -->
              <!-- <li [ngClass]="{'active': current_page == 'stats'}">
                <a routerLink="/stats" href="javascript:void(0)" (click)="collapseMenu()" translate>Activity</a>
              </li> -->
              <li *ngIf="user!=null">
                <a [routerLink]="'/profile/'+user.user_id+'/collected'" href="javascript:void(0)" (click)="collapseMenu()" translate>My Profile</a>
              </li>
              <!--              <li [ngClass]="{'active': current_page == 'login'}" *ngIf="user==null">-->
<!--                <a routerLink="/login" href="javascript:void(0)" (click)="collapseMenu()" translate>Login</a>-->
<!--              </li>-->
              <li [ngClass]="{'active': current_page == 'login'}" *ngIf="user!=null">
                <a href="javascript:void(0)"><img [src]="mediaBase + '/images/user/' + profile_image" class="profileImage"
                    alt="" />{{user.first_name}}
                  {{user.last_name}}</a>
                <ul>
                  <li><a href="javascript:void(0)" [routerLink]="'/profile/'+user.user_id+'/collected'"
                      (click)="collapseMenu()" translate>My Profile</a></li>
                  <li><a href="javascript:void(0)" [routerLink]="'/collection/mycollection'" (click)="collapseMenu()"
                      translate>My Collection</a></li>
                  <li><a href="javascript:void(0)" [routerLink]="'/profile/'+user.user_id+'/favorited'"
                      (click)="collapseMenu()" translate>My Favorites</a></li>
                  <!-- <li><a href="javascript:void(0)" [routerLink]="'/profile/'+user.user_id+'/refer'"
                      (click)="collapseMenu()" translate>Referral program</a></li>
                  <li><a href="javascript:void(0)" [routerLink]="'/profile/'+user.user_id+'/affiliates'"
                      (click)="collapseMenu()" translate>My Affiliates</a></li> -->
                  <li><a href="javascript:void(0)" #btnLogout id="linkLogout" (click)="logoutAction()" (click)="collapseMenu()"
                      translate>Logout</a></li>
                </ul>
              </li>

              <li>
                <a href="javascript:void(0)">
                  <em class="flag-icon flag-icon-{{current_lang}}"></em>
                  <label class="flag-pl5"> {{current_lang | translate}}</label>
                </a>
                <ul class="flag-custom">
                  <li>
                    <a href="javascript:void(0)" (click)="changeLang(lang)" class="flag-alt"
                      *ngFor="let lang of availableLangs()">
                      <em class="flag-icon flag-icon-{{lang}}"></em>
                      <label class="flag-pl5"> {{lang | translate}}</label>
                    </a>
                  </li>
                </ul>
              </li>

              <!-- until here -->
            </ul>
            <div class="menu_side_area">
              <a href="javascript:void(0)" #btnConnecWallet id="linkConnecWallet" (click)="connectWallet()" class="newbtn btnsize1" *ngIf="user==null">Wallet</a>
              <a [routerLink]="['/collection/add']" class="btn-main btn-coll-add" style="color: rgb(8, 11, 25)"  *ngIf="user!=null" translate>Create</a>
              <span id="menu-btn"></span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</header>
<!-- header close -->

<router-outlet></router-outlet>

<!-- footer begin -->
<footer style="background-color: rgb(8, 11, 25)">
  <div class="container">
    <div class="row">
      <div class="col-md-4">

      </div>
      <div class="col-md-2 col-sm-6 col-xs-1">
        <div class="widget" *ngIf="user">
          <h5 translate>My Account</h5>
          <ul>
            <li><a href="javascript:void(0)" [routerLink]="'/profile/'+user.user_id+'/collected'" translate>My Profile</a></li>
            <li><a href="javascript:void(0)" [routerLink]="'/profile/'+user.user_id+'/favorited'" translate>My Favorites</a></li>
            <li><a href="javascript:void(0)" (click)="logoutAction()" translate>Logout</a></li>
          </ul>
        </div>
      </div>
      <div class="col-md-2 col-sm-6 col-xs-1">
        <div class="widget">
          <h5>Marketplace</h5>
          <ul>
            <li><a [routerLink]="['/marketplace']">All NFTs</a></li>
            <li *ngFor="let item of categories"><a [routerLink]="['/marketplace']"
                [queryParams]="{category_id: item._id}">{{item.title}}</a></li>
          </ul>
        </div>
      </div>
      <div class="col-md-2 col-sm-6 col-xs-1">
        <div class="widget">
          <h5 translate>Legal</h5>
          <ul>
            <li><a href="#" translate>Legal warning</a></li>
            <li><a href="#" translate>Privacy Policy</a></li>
            <li><a href="#" translate>Cookies Policy</a></li>
          </ul>
        </div>
      </div>
      <div class="col-md-2 col-sm-6 col-xs-1">
        <div class="widget">
        
        </div>
      </div>
    </div>
  </div>
</footer>
<!-- footer close -->
